export const animateRect = (
  element,
  duration,
  initialRotation,
  middleRotation,
  translationX,
  translationY
) => {
  const keyframes = [
    {
      transform: `rotate(${initialRotation}deg) translate(0, 0)`,
      easing: 'ease-in-out'
    },
    {
      transform: `rotate(${middleRotation}deg) translate(${translationX}px, ${translationY}px)`,
      easing: 'ease-in-out'
    },
    {
      transform: `rotate(${initialRotation}deg) translate(0, 0)`,
      easing: 'ease-in-out'
    }
  ]

  const options = {
    duration,
    iterations: Infinity
  }
  element.animate(keyframes, options)

  // needed later
  // const animation = element.animate(keyframes, options)
  // animation.play()
}

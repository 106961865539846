import React, { useState, useEffect, useRef } from 'react'
import Navigation from '../../Navigation/Navigation'
import { QuizQuestion } from '../QuizQuestion/QuizQuestion'
import styles from './quizContainer.module.scss'
import { useParams, useNavigate } from 'react-router-dom'
import { animateRect } from '../../../hooks/animateRectHook'

const options = [
  {
    id: 1,
    answer: 'Actively seek out positive and balanced news sources'
  },
  {
    id: 2,
    answer: 'Engage in critical thinking and question news headlines'
  },
  {
    id: 3,
    answer:
      'Be aware of your own biases and consciously strive for a balanced perspective'
  },
  {
    id: 4,
    answer: 'All of the above'
  }
]

const sliderOptions = [
  {
    id: 1,
    option: "neutral, it's just another news story"
  },
  {
    id: 2,
    option: 'angry or fearful'
  },
  {
    id: 3,
    option: 'curious about the details'
  }
]

const questions = [
  {
    id: 1,
    text: "True or false: The media's focus on negativity can distort our perception of reality.",
    type: 'trueFalse',
    options: null,
    answer: 'true',
    information: 'True! The media often highlights negative events and stories, which can create a skewed view of the world by neglecting positive or neutral events.'
  },
  {
    id: 2,
    text: "True or false: Constantly reading negative news reinforces a person's pessimistic outlook on the world.",
    type: 'trueFalse',
    options: null,
    answer: 'true',
    information: 'True! When you are exposed to a steady stream of negative news stories, it can contribute to feelings of fear, anxiety and pessimism.'
  },
  {
    id: 3,
    text: 'True or false: Overcoming the negativity bias can lead to a more accurate and balanced understanding of the world.',
    type: 'trueFalse',
    options: null,
    answer: 'true',
    information: 'By actively seeking out a more balanced perspective, we can gain a more accurate understanding of reality.'
  },
  {
    id: 4,
    text: 'Choose: What are ways to counteract the negativity bias in our perception of news?',
    type: 'multipleChoice',
    options,
    answer: [[1, 2, 3], [4]],
    information: null
  },
  {
    id: 5,
    text: 'Devastating Natural Disaster Strikes, Lives Lost',
    type: 'slider',
    options: sliderOptions,
    answer: null,
    information: null
  },
  {
    id: 6,
    text: "This headline capitalizes in the negativity bias by emphasising the devastation and loss of life caused by a natural disaster. While it's importang to acknowledge the impact and offer support, it's also essential to consider stories of resilience and recovery.",
    type: 'text',
    options: null,
    answer: null,
    information: null
  },
  {
    id: 7,
    text: 'Community rallies together in the aftermath of natural disaster, rebuilding efforts underway',
    type: 'example',
    options: null,
    answer: null,
    information: null
  },
  {
    id: 8,
    text: 'Violence Errupts at Peaceful Protest, Chaos Ensues',
    type: 'slider',
    options: sliderOptions,
    answer: null,
    information: null
  },
  {
    id: 9,
    text: 'This headline exploits the negativity bias by emphasizing the chaos and violence that unfolded during what was initially a peaceful protest. It amplifies the negative aspects of the situation, drawing attention to the disorder and conflict.',
    type: 'text',
    options: null,
    answer: null,
    information: null
  },
  {
    id: 10,
    text: 'Protest Takes an Unfortunate Turn, Authorities Manage Unrest',
    type: 'example',
    options: null,
    answer: null,
    information: null
  },
  {
    id: 11,
    text: "Scandal Rocks Celebrity's Life, Career in Jeopardy",
    type: 'slider',
    options: sliderOptions,
    answer: null,
    information: null
  },
  {
    id: 12,
    text: "This headline takes advantage of the negativity bias by emphasizing the scandal that has rocked a celebrity's life. One could provide a more neutral tone by acknowledging the controversial incident that has occurred without sensationalizing it and raising questions about the celebrity's public image and professional future.",
    type: 'text',
    options: null,
    answer: null,
    information: null
  },
  {
    id: 13,
    text: "Controversial Incident Threatens Celebrity's Career, Public Image in Question",
    type: 'example',
    options: null,
    answer: null,
    information: null
  }
]

function applyRectStyling (index) {
  const rect1 = document.querySelector(`.${styles.rect1}`)
  const rect2 = document.querySelector(`.${styles.rect2}`)
  const rect3 = document.querySelector(`.${styles.rect3}`)

  if (rect1 && rect2 && rect3) {
    switch (index) {
      case 1:
        animateRect(rect1, 5000, 48, 40, 10, 5)
        animateRect(rect2, 4000, 120, 125, 10, 5)
        animateRect(rect3, 4000, 50, 55, 10, 5)
        break
      case 2:
        animateRect(rect1, 5000, 50, 40, 10, 5)
        animateRect(rect2, 4000, 140, 145, 10, 5)
        animateRect(rect3, 4000, 50, 55, 10, 5)
        break
      case 3:
        animateRect(rect1, 5000, 30, 40, 10, 5)
        animateRect(rect2, 4000, 140, 145, 10, 5)
        animateRect(rect3, 4000, 50, 55, 10, 5)
        break
      default:
        break
    }
  }
}

function applyRectAnimation (index) {
  const rect1 = document.querySelector(`.${styles.rect1}`)
  const rect2 = document.querySelector(`.${styles.rect2}`)
  const rect3 = document.querySelector(`.${styles.rect3}`)

  switch (index) {
    case 1:
      rect1.style.left = '0vw'
      rect1.style.top = '-10vh'

      rect2.style.top = '20vh'
      rect2.style.left = '95vw'

      rect3.style.left = '17vw'
      rect3.style.top = '85vh'
      break
    case 2:
      rect1.style.left = '-10vw'
      rect1.style.top = '5vh'

      rect2.style.top = '-8vh'
      rect2.style.left = '90vw'

      rect3.style.left = '50vw'
      rect3.style.top = '83vh'
      break
    case 3:
      rect1.style.left = '-8vw'
      rect1.style.top = '40vh'

      rect2.style.top = '-5vh'
      rect2.style.left = '80vw'

      rect3.style.left = '85vw'
      rect3.style.top = '70vh'
      break
    default:
      break
  }
}

function animateQuestion (questionRef) {
  const questionElement = questionRef
  questionElement.animate(
    [
      { opacity: 0, transform: 'translateY(10px)' },
      { opacity: 1, transform: 'translateY(0)' }
    ],
    { duration: 1000, easing: 'ease-in-out' }
  )
}

export const QuizContainer = () => {
  const questionRef = useRef(null)
  const { questionId } = useParams()
  const initialQuestionIndex = parseInt(questionId, 10)
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(
    isNaN(initialQuestionIndex) ? 0 : initialQuestionIndex
  )

  const navigate = useNavigate()

  useEffect(() => {
    const handlePopState = () => {
      const previousQuestionIndex = currentQuestionIndex - 1
      if (previousQuestionIndex >= 0) {
        setCurrentQuestionIndex(previousQuestionIndex)
      } else {
        const path = window.location.pathname
        if (path === '/quiz/end') {
          navigate('/quiz/12')
        }
      }
    }
    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [currentQuestionIndex])

  useEffect(() => {
    animateQuestion(questionRef.current)
  }, [currentQuestionIndex])

  useEffect(() => {
    if (currentQuestionIndex === 0) {
      animateQuestion(questionRef.current)
    }
  }, [])

  const handleAnswerButtonClick = () => {
    const nextQuestionIndex = currentQuestionIndex + 1

    if (nextQuestionIndex >= questions.length) {
      navigate('/quiz/end')
      return
    }

    setCurrentQuestionIndex(nextQuestionIndex)

    setTimeout(() => {
      navigate(`/quiz/${nextQuestionIndex}`)
    }, 100)
  }

  useEffect(() => {
    const newIndex = ((currentQuestionIndex - 1) % 3) + 1
    applyRectStyling(newIndex)
    applyRectAnimation(newIndex)
  }, [currentQuestionIndex])

  if (currentQuestionIndex >= 0 && currentQuestionIndex < questions.length) {
    const { text, type, options, answer } = questions[currentQuestionIndex]
    return (
      <div className={styles.containerWrapper}>
        <div className={`${styles.rect1} ${styles.rectAnimated}`}>
          <div className={styles.rectInsideColor}></div>
          <div className={styles.rectInsideBG}></div>
          <div className={styles.rectOutsideBG}></div>
        </div>
        <div className={`${styles.rect2} ${styles.rectAnimated}`}>
          <div className={styles.rectInsideColor}></div>
          <div className={styles.rectInsideBG}></div>
          <div className={styles.rectOutsideBG}></div>
        </div>
        <div className={`${styles.rect3} ${styles.rectAnimated}`}>
          <div className={styles.rectInsideColor}></div>
          <div className={styles.rectInsideBG}></div>
          <div className={styles.rectOutsideBG}></div>
        </div>
        <Navigation />
        <div className={styles.question} ref={questionRef}>
          <QuizQuestion
            text={text}
            type={type}
            options={options}
            answer={answer}
            information={ questions[currentQuestionIndex].information}
          />
          <button
            className={styles.nextButton}
            type='button'
            onClick={() => handleAnswerButtonClick()}>
            Next
          </button>
        </div>
      </div>
    )
  } else {
    return <div>Invalid question</div>
  }
}
export default QuizContainer

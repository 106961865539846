import React, { useEffect } from 'react'
import Navigation from '../Navigation/Navigation'
import styles from './startScreen.module.scss'
import { useNavigate } from 'react-router-dom'
import { animateRect } from '../../hooks/animateRectHook'
import { Button } from '../Button/Button/Button'

export const StartScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
    const rect1 = document.querySelector(`.${styles.rect1}`)
    const rect2 = document.querySelector(`.${styles.rect2}`)
    const rect3 = document.querySelector(`.${styles.rect3}`)

    animateRect(rect1, 5000, 48, 40, 10, 5)
    animateRect(rect2, 4000, 120, 125, 10, 5)
    animateRect(rect3, 4000, 140, 145, 10, 5)
  }, [])

  const navigate = useNavigate()
  const handleClick = () => {
    navigate('/quiz/1')
  }

  return (
    <div className={styles.pageWrapper}>
      <Navigation></Navigation>
      <div className={styles.startScreenWrapper}>
        <div className={styles.fixedText}>
          <h1>Negativity Bias</h1>
        </div>
        <div className={`${styles.rect1} ${styles.rectAnimated}`}>
          <div className={styles.rectInsideColor}></div>
          <div className={styles.rectInsideBG}></div>
          <div className={styles.rectOutsideBG}></div>
        </div>
        <div className={`${styles.rect2} ${styles.rectAnimated}`}>
          <div className={styles.rectInsideColor}></div>
          <div className={styles.rectInsideBG}></div>
          <div className={styles.rectOutsideBG}></div>
        </div>
        <div className={`${styles.rect3} ${styles.rectAnimated}`}>
          <div className={styles.rectInsideColor}></div>
          <div className={styles.rectInsideBG}></div>
          <div className={styles.rectOutsideBG}></div>
        </div>
        <p className={styles.text1}>
          The negativity bias in news media distorts reality, fuels irrational
          fear, and reinforces pessimism, trapping individuals in a cycle of
          biased and negative perception.
        </p>
        <p className={styles.text2}>
          The media exploits the negativity bias by prioritizing and excessively
          focusing on negative, sensational, and exceptional events, which grab
          attention and generate higher audience ratings and clicks.
        </p>
        <p className={styles.text3}>
          Sensational and exceptional events receive disproportionate attention,
          distorting the overall worldview presented to audiences.
        </p>
        <div className={styles.question}>
          <p>Are you aware of the negativity bias?</p>
          <Button inverted onClick={() => handleClick()}>
            Find out
          </Button>
        </div>
      </div>
    </div>
  )
}

export default StartScreen

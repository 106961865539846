// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loadingSpinner_loadingSpinner__SQ9WB {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  animation: loadingSpinner_spin__OjeBP 1s infinite;\n}\n.loadingSpinner_loadingSpinner__SQ9WB img {\n  margin-left: 0.5rem;\n  animation: loadingSpinner_spinImage__uOL9o 1s infinite;\n}\n@keyframes loadingSpinner_spin__OjeBP {\n  0% {\n    transform: rotateX(0deg);\n  }\n  100% {\n    transform: rotateX(360deg);\n  }\n}\n@keyframes loadingSpinner_spinImage__uOL9o {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}", "",{"version":3,"sources":["webpack://./src/stories/LoadingSpinner/loadingSpinner.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,iDAAA;AACJ;AAAI;EACI,mBAAA;EACA,sDAAA;AAER;AAAI;EACI;IACI,wBAAA;EAEV;EAAM;IACI,0BAAA;EAEV;AACF;AACI;EACI;IACI,uBAAA;EACV;EACM;IACI,yBAAA;EACV;AACF","sourcesContent":[".loadingSpinner{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    animation: spin 1s infinite;\n    img{\n        margin-left: .5rem;\n        animation: spinImage 1s infinite;\n    }\n    @keyframes spin {\n        0%{\n            transform: rotateX(0deg);\n        }\n        100%{\n            transform: rotateX(360deg);\n        }\n    }\n\n    @keyframes spinImage {\n        0%{\n            transform: rotate(0deg);\n        }\n        100%{\n            transform: rotate(360deg);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingSpinner": "loadingSpinner_loadingSpinner__SQ9WB",
	"spin": "loadingSpinner_spin__OjeBP",
	"spinImage": "loadingSpinner_spinImage__uOL9o"
};
export default ___CSS_LOADER_EXPORT___;

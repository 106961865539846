import React from 'react'
import { MultipleChoice } from '../Multiple-Choice-Question/MultipleChoice'
import SliderQuestion from '../Slider-Question/SliderQuestion'
import TrueFalseQuestion from '../True-False-Question/TrueFalseQuestion'
import styles from '../questions.module.scss'

export const QuizQuestion = ({ text, type, options, answer, information }) => {
  let questionComponent

  switch (type) {
    case 'trueFalse':
      questionComponent = <TrueFalseQuestion question={text} answer={answer} information={information}/>
      break
    case 'multipleChoice':
      questionComponent = <MultipleChoice question={text} options={options}/>
      break
    case 'slider':
      questionComponent = <SliderQuestion question={text} sliderOptions={options}/>
      break
    case 'text':
      questionComponent = <p>{text}</p>
      break
    case 'example':
      questionComponent = (
            <div className={styles.exampleWrapper}>
              <span>This is what it would sound like if it was neutral:</span>
              <p>&ldquo;{text}&rdquo;</p>
            </div>
      )
      break
    default:
      questionComponent = null
  }

  return (
        <div className={styles.questionComponentWrapper}>
            {questionComponent}
        </div>
  )
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RotateIcon_spinnerSVG__4Dj0F {\n  position: absolute;\n  top: -300px;\n  left: -300px;\n  height: 600px;\n  width: 600px;\n  opacity: 0;\n  animation: RotateIcon_spinning__hu4e2 22000ms linear 0ms infinite forwards, RotateIcon_afterPreloadAnimationSpinner__9dYdb 750ms ease-in-out 1750ms 1 forwards;\n}\n@media (max-width: 600px) {\n  .RotateIcon_spinnerSVG__4Dj0F {\n    top: -250px;\n    left: -200px;\n    height: 400px;\n    width: 400px;\n  }\n}\n\n@keyframes RotateIcon_afterPreloadAnimationSpinner__9dYdb {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n@keyframes RotateIcon_spinning__hu4e2 {\n  to {\n    transform: rotate(360deg);\n  }\n}", "",{"version":3,"sources":["webpack://./src/stories/LandingPage/Header/RotateIcon.module.scss","webpack://./src/stories/mixins.scss"],"names":[],"mappings":"AAGA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,YAAA;EACA,UAAA;EASA,8JAAA;AAVF;ACPI;EDEJ;IASI,WAAA;IACA,YAAA;IACA,aAAA;IACA,YAAA;EAAF;AACF;;AAMA;EACE;IACE,UAAA;EAHF;EAKA;IACE,UAAA;EAHF;AACF;AAMA;EACE;IACE,yBAAA;EAJF;AACF","sourcesContent":["@import '../../variables.scss';\n@import '../../mixins.scss';\n\n.spinnerSVG {\n  position: absolute;\n  top: -300px;\n  left: -300px;\n  height: 600px;\n  width: 600px;\n  opacity: 0;\n\n  @include mobile {\n    top: -250px;\n    left: -200px;\n    height: 400px;\n    width: 400px;\n  }\n\n  animation: spinning $spinningTime linear 0ms infinite forwards,\n    afterPreloadAnimationSpinner $fadeInTime ease-in-out $delayFadeIn 1 forwards;\n}\n\n@keyframes afterPreloadAnimationSpinner {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n@keyframes spinning {\n  to {\n    transform: rotate(360deg);\n  }\n}\n","@mixin mobile {\n    @media (max-width: 600px) {\n        @content;\n    }\n}\n\n@mixin desktop {\n    @media (min-width: 601px) {\n        @content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinnerSVG": "RotateIcon_spinnerSVG__4Dj0F",
	"spinning": "RotateIcon_spinning__hu4e2",
	"afterPreloadAnimationSpinner": "RotateIcon_afterPreloadAnimationSpinner__9dYdb"
};
export default ___CSS_LOADER_EXPORT___;

import { React, useState } from 'react'
import styles from './navigation.module.scss'
import { Link, useLocation } from 'react-router-dom'

export const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { pathname } = useLocation()

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className={`${styles.navWrapper} ${isOpen ? styles.open : styles.closed}`}>
        <nav>
            <div>
                <Link to='/'>Home</Link>
                {!pathname.startsWith('/news') && <Link to='/news/all'>News</Link>}
                <Link to='/imprint'>Imprint</Link>
            </div>
        </nav>
        <div className={`${styles.hamburger} ${isOpen ? styles.open : styles.closed}`} onClick={toggleMenu}>
          <div className={styles.line1}></div>
          <div className={styles.line2}></div>
      </div>
    </div>
  )
}

export default Navigation

import { useState, useMemo, useEffect } from 'react'

export const useHTTP = (url) => {
  const [response, setResponse] = useState(undefined)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  const fetchData = useMemo(() => {
    return () => {
      setIsLoading(true)
      fetch(url)
        .then((response) => response.json())
        .then((json) => {
          if (json.status && json.status !== 200) {
            setResponse(undefined)
            setError(true)
            setIsLoading(false)
            return
          }
          setResponse(json)
          setIsLoading(false)
        })
        .catch((error) => {
          setError(error)
          setIsLoading(false)
        })
    }
  }, [url])

  useEffect(() => {
    fetchData()
  }, [fetchData, url])

  return {
    response,
    isLoading,
    error,
    refetch: fetchData
  }
}

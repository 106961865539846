import React, { useEffect } from 'react'
import styles from './newsPage.module.scss'
import { Header } from '../Header/Header'
import { useCategoriesStore } from '../../stores/categoriesStore'
import { useParams } from 'react-router-dom'
import { ErrorBox } from '../ContentLoader/ErrorBox'
import { ArticleLoader } from '../ArticleLoader/ArticleLoader'
import { ButtonLink } from '../Button/ButtonLink/ButtonLink'
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner'

export const NewsPage = () => {
  const { category } = useParams()
  const {
    allCategories,
    allCategoriesLoading,
    allCategoriesError,
    fetchAllCategories
  } = useCategoriesStore()

  useEffect(() => {
    fetchAllCategories()
  }, [fetchAllCategories])

  const categoryObj =
    category === 'all'
      ? { slug: 'all' }
      : allCategories?.find((cat) => {
        return cat.slug === category
      })
  return (
    <>
      {allCategoriesLoading &&
        <LoadingSpinner className={styles.loader}></LoadingSpinner>
      }
      {(allCategoriesError || !allCategories) && (
        <ErrorBox error='true' className={styles.fullScreen}>
          <h1>Could not load articles, please try again later</h1>
        </ErrorBox>
      )}
      {!allCategoriesError && !allCategoriesLoading && allCategories && (
        <>
          <Header
            loggedIn={false}
            categoriesLoading={allCategoriesLoading}
            categories={allCategories}
            selected={category}></Header>
          <div className={`${styles.flex_h} ${styles.newsFeed}`}>
            <div className={styles.allArticlesWrapper}>
              <div className={styles.newsHeader}>
                {category === 'all' && <h2>All articles</h2>}
                {category !== 'all' && (
                  <h2>
                    All articles of category:{' '}
                    <span className={styles.boldRed}>
                      {categoryObj ? categoryObj.name : ''}
                    </span>
                  </h2>
                )}
                <ButtonLink size='small' to='/article/create' inverted>
                  Create your own article
                </ButtonLink>
              </div>
              <ArticleLoader category={categoryObj}></ArticleLoader>
            </div>
          </div>
        </>
      )}
    </>
  )
}

import React from 'react'
import styles from './imprint.module.scss'
import Navigation from '../Navigation/Navigation'

export const Imprint = () => {
  return (
        <div>
            <Navigation></Navigation>
            <div className={styles.wrapper}>
              <div className={styles.divider}></div>
              <div className={styles.imprint}>
              <h1>Imprint for Positivity Bias</h1>
                <p>POSITIVITY BIAS is a website that provides news generated by AI for entertainment purposes only.</p>
                <p>Please be aware that all news articles on this website are not real and are solely for entertainment purposes. None of the articles published on this website should be taken seriously or used as a source of factual information.</p>
                <p>The spread of false information and misinformation can have serious consequences and is harmful to society. The creators of this website do not endorse or support the spread of false information, and all articles generated by AI are strictly for entertainment purposes.</p>
                <p>The news provided by POSITIVITY BIAS are generated by ChatGPT. The images corresponding to the news are generated by DALL-E.</p>
                <p>This project has been created by Elias Fellinger, David Grois and Tanja Gruber as part of the Bachelors degree program in MultiMediaTechnology at the University of Applied Sciences Salzburg.</p>
              </div>
              <div className={styles.legalDisclaimer}>
              <h2>Legal Disclaimer</h2>
                <p>All content on POSITIVITY BIAS is created for entertainment purposes only. We do not endorse or support the spread of false information or the use of our content for any illegal or unethical purposes. By accessing this website, you agree to use the content solely for entertainment purposes and not to rely on it as a source of factual information.</p>
                <p>We do not accept any responsibility or liability for any loss or damage incurred by users of our website as a result of using our content. We reserve the right to modify or remove any content on our website at any time without notice.</p>
              </div>
              <span className={styles.credits}>© Elias Fellinger, David Grois, Tanja Gruber 2023</span>
            </div>
        </div>
  )
}
export default Imprint

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".errorBox_loader__LuYn0 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-height: -moz-fit-content;\n  min-height: fit-content;\n  min-width: -moz-fit-content;\n  min-width: fit-content;\n}", "",{"version":3,"sources":["webpack://./src/stories/ContentLoader/errorBox.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,4BAAA;EAAA,uBAAA;EACA,2BAAA;EAAA,sBAAA;AACJ","sourcesContent":[".loader{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    min-height: fit-content;\n    min-width: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "errorBox_loader__LuYn0"
};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import styles from './generateArticlePage.module.scss'
import { Button } from '../Button/Button/Button'
import { Navigation } from '../Navigation/Navigation'
import { BACKEND_URL } from '../../http'
import { ButtonLink } from '../Button/ButtonLink/ButtonLink'
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner'

const GenerationSchema = Yup.object().shape({
  prompt: Yup.string().max(100, 'Prompt cannot be longer than 100 characters').required('Please enter a prompt')
})

export const GenerateArticePage = () => {
  const [futureArticle, setFutureArticle] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [throttled, setThrottled] = useState(false)

  const form = useFormik({
    initialValues: { prompt: '' },
    onSubmit: async (values) => {
      setLoading(true)

      let tmpArticle
      const maxTimeout = 50000
      const interval = 3000

      const response = await fetch(`${BACKEND_URL}/v1/articles`, {
        method: 'POST',
        body: JSON.stringify({
          prompt: values.prompt
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })

      if (response.status === 429) {
        setThrottled(true)
        return
      }

      const json = await response.json()
      if (json.id) {
        tmpArticle = json.id
      } else {
        setError(true)
        return
      }

      const checkForValidResponse = async (elapsedTime) => {
        const articleResponse = await fetch(`${BACKEND_URL}/v1/articles/${tmpArticle}`)

        if (articleResponse.status === 404) {
          if (elapsedTime >= maxTimeout) {
            setError(true)
            return
          }

          setTimeout(() => {
            checkForValidResponse(elapsedTime + interval)
          }, interval)
        } else {
          setFutureArticle(tmpArticle)
        }
      }

      checkForValidResponse(0)
    },
    validationSchema: GenerationSchema
  })

  return (
    <>
      <Navigation></Navigation>
      {loading &&
        <div className={styles.overlayContainer}>
          <div className={styles.overlay}>
            {(!futureArticle && !error && !throttled) &&
              <>
                <h2>Your article is currently generating...</h2>
                <LoadingSpinner className={styles.spinner}></LoadingSpinner>
              </>
            }

            {throttled &&
              <>
                <h2>Too many users tried generating an article</h2>
                <p>We can only support a specific amount of user requests per hour because of funding, please try again later!</p>
                <ButtonLink to={'/news/all'} inverted>Go Home</ButtonLink>
              </>
            }

            {error &&
              <>
                <h2>There occured an error generating your article</h2>
                <p>ChatGPT could not generate a suitable answer to your request, please try again later!</p>
                <ButtonLink to={'/news/all'} inverted>Go back</ButtonLink>
              </>
            }

            {(futureArticle && !error) &&
              <>
                <h2>Hurrayy! Your article is now ready!</h2>
                <ButtonLink to={`/article/${futureArticle}`} inverted>I want to read it!</ButtonLink>
              </>
            }
          </div>
        </div>
      }
      <div className={styles.content}>
        <div className={styles.wrapper}>
          <div className={styles.wrapperText}>
            <h2>Generate a custom article here!</h2>
            <p>
              Tell ChatGPT what to write about and it will generate a positive news article for you to enjoy!
              We currently can only fund a few requests per day and only one per IP Adress, so use your prompt wisely!
            </p>
          </div>
          <form onSubmit={form.handleSubmit}>
            <div className={styles.formControls}>
              <label htmlFor="prompt-input">Enter a prompt</label>
              <input id='prompt-input' name='prompt' value={form.values.prompt} onChange={form.handleChange} placeholder='Funny birds join chirp contest'/>
              {form.errors.prompt &&
                <p className={styles.error}>{form.errors.prompt}</p>
              }
            </div>
            <Button type="submit" inverted>Generate article</Button>
          </form>
        </div>
      </div>
    </>
  )
}

import React from 'react'
import * as PropTypes from 'prop-types'
import styles from './continueReadingSection.module.scss'
import { Article } from '../Article/Article'

export const ContinueReadingSection = ({ className, content }) => {
  return (
    <section className={`${styles.continueReading} ${className}`}>
      <h2>Might be interesting</h2>
      <ul>
        {content.map((article) => (
          <li key={article.id} className={styles.mostLikedArticle}><Article className={styles.article} content={article}></Article></li>
        ))}
      </ul>
    </section>
  )
}

ContinueReadingSection.propTypes = {
  className: PropTypes.string,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      category: PropTypes.string,
      date: PropTypes.instanceOf(Date),
      heading: PropTypes.string,
      imageURL: PropTypes.string,
      imageAlt: PropTypes.string,
      text: PropTypes.string
    })
  ).isRequired
}

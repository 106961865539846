import React from 'react'
import * as PropTypes from 'prop-types'
import Navigation from '../Navigation/Navigation'
import FilterBar from '../FilterBar/FilterBar'
import styles from './header.module.scss'

export const Header = ({ categories, categoriesLoading, selected }) => {
  console.log('sleected', selected)
  return (
    <div className={styles.headerWrapper}>
      <Navigation></Navigation>
      <h1>Positivity Bias</h1>
      {!categoriesLoading && categories && categories.length !== 0 && (
        <FilterBar
          categories={categories}
          loading={categoriesLoading}
          selected={selected}></FilterBar>
      )}
      {(categoriesLoading || !categories || categories.length === 0) && (
        <div className={styles.loader}>Loading</div>
      )}
    </div>
  )
}

export default Header

Header.propTypes = {
  loggedIn: PropTypes.bool,
  categoriesLoading: PropTypes.bool,
  selected: PropTypes.string
}

Header.defaultProps = {
  loggedIn: false
}

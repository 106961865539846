import React, { useEffect } from 'react'
import styles from './singleArticlePage.module.scss'
import { useParams, useLocation } from 'react-router-dom'
import { useHTTP } from '../../hooks/httpHook'
import { BACKEND_URL } from '../../http'
import SingleArticle from '../SingleArticle/SingleArticle'
import { convertArticle } from '../../converters/articleConverters'
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner'
import { Navigation } from '../Navigation/Navigation'

export const SingleArticlePage = () => {
  const { id } = useParams()
  const { response: article, loading: articleLoading, error: articleError } = useHTTP(`${BACKEND_URL}/v1/articles/${id}`)
  const { response: randomArticles, loading: randomArticlesLoading, error: randomArticlesError, refetch: refetchRandomArticles } = useHTTP(`${BACKEND_URL}/v1/articles/random`)
  const { pathname } = useLocation()

  useEffect(() => {
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual'
    }
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    refetchRandomArticles()
  }, [id])

  return (
    <>
      <Navigation></Navigation>
      <button className={styles.backButton} onClick={() => history.back()}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
      </button>
      {(!articleLoading && !randomArticlesLoading && !randomArticlesError && randomArticles && article) &&
        <SingleArticle
          className={styles.marginY}
          content={convertArticle(article)}
          continueReadingArticles={randomArticles.map((article) => convertArticle(article))}
        >
        </SingleArticle>
      }
      {(!article && articleError) && <div className={styles.error}><h1>Could not load requested article</h1></div>}
      {(!article && !articleError) && <LoadingSpinner className={styles.spinner}></LoadingSpinner>}
    </>
  )
}

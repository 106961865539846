import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { convertArticle } from '../../converters/articleConverters'
import Article from '../Article/Article'
import { BACKEND_URL } from '../../http'
import styles from './articleLoader.module.scss'
import { useInfiniteEndpoint } from '../../hooks/infiniteScrollHook'

export const ArticleLoader = ({ category }) => {
  const url =
    category.slug === 'all'
      ? `${BACKEND_URL}/v1/articles?page=$page`
      : `${BACKEND_URL}/v1/categories/${category.slug}/articles?page=$page`
  const {
    response: articles,
    hasMore: hasMoreArticles,
    fetch: fetchArticles
  } = useInfiniteEndpoint(url, convertArticle)

  return (
    <InfiniteScroll
      dataLength={articles ? articles.length : 0}
      next={fetchArticles}
      hasMore={hasMoreArticles}
      loader={<h4>Loading...</h4>}
      endMessage={<h4>No more articles left</h4>}
      className={styles.loaderWrapper}>
      {articles && (
        <div className={styles.allArticles}>
          {articles.map((article, i) => {
            return (
              <Article
                key={i}
                content={article}
                className={styles.article}></Article>
            )
          })}
        </div>
      )}
    </InfiniteScroll>
  )
}

import React, { useState } from 'react'
import styles from './slider.module.scss'

export const Slider = ({ options }) => {
  const [value, setValue] = useState(0)

  const handleSliderChange = (event) => {
    setValue(parseInt(event.target.value))
  }

  return (
      <div className={styles.rangeContainer}>
          <input type="range" min="0" max="2" step="1" value={value} onChange = {handleSliderChange} style={{
            '--c': 'red',
            '--l': '4px',
            '--w': '20px',
            '--h': '20px'
          }} list="range-list"/>
          <datalist className={styles.RangeList}>
            <option value="0"></option>
            <option value="1"></option>
            <option value="2"></option>
          </datalist>
          <div className={styles.stepText}>
          <div className={styles.step1}>{options[0].option}</div>
          <div className={styles.step2}>{options[1].option}</div>
          <div className={styles.step3}>{options[2].option}</div>
          </div>
      </div>
  )
}

export default Slider

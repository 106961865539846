import React, { useState, useEffect } from 'react'
import { Button } from '../../Button/Button/Button'
import styles from '../questions.module.scss'

export const TrueFalseQuestion = ({ question, answer, information }) => {
  const [userAnswer, setUserAnswer] = useState(null)

  const handleButtonClick = (selectedAnswer) => {
    setUserAnswer(selectedAnswer)
  }

  useEffect(() => {
    setUserAnswer(null)
  }, [question])

  let answerText = null

  if (userAnswer !== null) {
    answerText = userAnswer === answer
      ? (
      <div className={`${styles.animCheck}`}>
        <div className={styles.check}></div>
      </div>
        )
      : (
        <div className={`${styles.animX}`}>
          <div className={styles.X}></div>
        </div>
        )
  }

  return (
    <div className={styles.trueFalseQuestionWrapper}>
      <span className={styles.questionType}>True or False</span>
      <p>{question}</p>
      <div className={styles.buttonWrapper}>
        <Button inverted onClick={() => handleButtonClick('true')}>
          True
        </Button>
        <Button inverted onClick={() => handleButtonClick('false')}>
          False
        </Button>
      </div>
      {answerText && <div>{answerText}</div>}
      {userAnswer && <div className={styles.message}>{information}</div>}
    </div>
  )
}

export default TrueFalseQuestion

import React, { useRef, useEffect } from 'react'
import * as PropTypes from 'prop-types'
import styles from './filterBar.module.scss'
import { Link } from 'react-router-dom'

export const FilterBar = ({ categories, selected }) => {
  const ref = useRef(null)

  useEffect(() => {
    const firstID = 'firstFilter'
    const lastID = 'lastFilter'
    ref.current.children[0].setAttribute('id', firstID)

    ref.current.children[ref.current.children.length - 1].setAttribute(
      'id',
      lastID
    )
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.target.getAttribute('id') === firstID) {
            if (!entry.isIntersecting) {
              document.documentElement.style.setProperty(
                '--opacityGradientLeft',
                1
              )
            } else {
              document.documentElement.style.setProperty(
                '--opacityGradientLeft',
                0
              )
            }
          }

          if (entry.target.getAttribute('id') === lastID) {
            if (entry.isIntersecting) {
              document.documentElement.style.setProperty(
                '--opacityGradientRight',
                0
              )
            } else {
              document.documentElement.style.setProperty(
                '--opacityGradientRight',
                1
              )
            }
          }
        })
      },
      { root: ref.current }
    )

    const observedElements = document.getElementById('filterBar')

    observer.observe(observedElements.children[0])
    observer.observe(
      observedElements.children[observedElements.children.length - 1]
    )

    return () => {
      observer.unobserve(observedElements.children[0])
      observer.unobserve(
        observedElements.children[observedElements.children.length - 1]
      )

      observer.disconnect()
    }
  }, [])
  return (
    <div className={styles.barWrapper}>
      <ul className={styles.filterBarWrapper} ref={ref} id='filterBar'>
        <Link
          key='all'
          className={selected === 'all' ? styles.selected : undefined}
          to='/news/all'>
          All
        </Link>
        {categories.map((category) => (
          <Link
            key={category.id}
            className={selected === category.slug ? styles.selected : undefined}
            to={`/news/${category.slug}`}>
            {category.name}
          </Link>
        ))}
      </ul>
    </div>
  )
}

export default FilterBar

FilterBar.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      category: PropTypes.shape({
        name: PropTypes.string,
        slug: PropTypes.string
      })
    })
  ).isRequired,
  selected: PropTypes.string
}

import React, { useEffect } from 'react'
import Navigation from '../../Navigation/Navigation'
import styles from '../questions.module.scss'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../Button/Button/Button'

export const EndScreen = () => {
  const navigate = useNavigate()

  const handleRestart = () => {
    navigate('/quiz/start')
  }

  const handleNews = () => {
    navigate('/news/all')
  }

  useEffect(() => {
    const handlePopstate = () => {
      navigate('/quiz/12')
    }

    window.addEventListener('popstate', handlePopstate)

    return () => {
      window.removeEventListener('popstate', handlePopstate)
    }
  }, [])

  return (
    <div className={styles.endScreenWrapper}>
      <Navigation />
      <div className={styles.endScreenGridWrapper}>
        <div className={styles.h1Wrapper}>
          <div className={styles.h1InnerWrapper}>
            <h1>Confronting the negativity bias</h1>
            <span>Confronting the negativity bias</span>
          </div>
        </div>
        <div className={styles.border}></div>
        <p className={styles.question}>
          Are you ready to see beyond the headlines?
        </p>
        <div className={styles.explanation}>
          <p>
            Don&apos;t let news headlines shape your perception. Learn to
            question headlines critically. Together, let&apos;s reshape our
            perception of news and build a more informed, optimistic world.
          </p>
          <div className={styles.endScreenButtons}>
            <Button inverted onClick={handleRestart}>
              Restart
            </Button>
            <Button inverted onClick={handleNews}>
              News
            </Button>
          </div>
        </div>
        <p className={styles.plea}>Question. Think. Overcome.</p>
      </div>
    </div>
  )
}

export default EndScreen

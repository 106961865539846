import { React, useEffect } from 'react'
import styles from './LandingPage.module.scss'
import { NavBarLanding } from './NavBarLanding/NavBarLanding'
import { Header } from './Header/Header'
import { animateRect } from '../../hooks/animateRectHook'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from '../Button/Button/Button'

export const LandingPage = () => {
  const trapID = 'trapLanding'
  const footerID = 'footerLanding'
  const navigate = useNavigate()

  function handleNewsButton () {
    navigate('/news/all')
  }

  function handleTakeQuizButton () {
    navigate('/quiz/start')
  }

  useEffect(() => {
    const followerPerId = document.getElementById('follower')
    let followerAnimActive = false

    setTimeout(() => {
      followerAnimActive = true
      startFollowerAnim()
    }, 2500)

    function startFollowerAnim () {
      if (!followerAnimActive) return
      document.body.onpointermove = (event) => {
        const { clientX, clientY } = event
        followerPerId.animate(
          {
            left: clientX + 'px',
            top: clientY + 'px'
          },
          { duration: 1000, fill: 'forwards' }
        )
      }
    }

    function stopFollowerAnim () {
      if (!followerAnimActive) return
      document.body.onpointermove = null
    }

    followerPerId.addEventListener('mouseenter', stopFollowerAnim)
    followerPerId.addEventListener('mouseleave', startFollowerAnim)

    const header = document.getElementsByTagName('header')[0]

    function hideFollwer () {
      if (window.scrollY > header.getBoundingClientRect().height) {
        followerPerId.style.display = 'none'
        followerPerId.style.animation = 'none'
        followerPerId.style.opacity = 1
      } else {
        followerPerId.style.display = 'block'
      }
    }
    document.addEventListener('scroll', hideFollwer)

    const rectLeft = document.getElementById('rectLeft')
    const rectRight = document.getElementById('rectRight')

    animateRect(rectLeft, 8500, -6, -18, 10, 15)
    animateRect(rectRight, 9500, 15, 23, 16, 21)

    return () => {
      document.removeEventListener('scroll', hideFollwer)

      followerPerId.removeEventListener('mouseenter', stopFollowerAnim())
      followerPerId.removeEventListener(
        'mouseleave',
        startFollowerAnim(followerPerId)
      )
    }
  }, [])
  return (
    <>
      <div className={styles.follower} id='follower'>
        <NavBarLanding></NavBarLanding>
      </div>
      <Header></Header>
      <main className={styles.contentWrapper}>
        <div className={styles.upperTextWrapper}>
          <div
            className={`${styles.rectLeft} ${styles.rectAnimated}`}
            id='rectLeft'>
            <div className={styles.rectInsideColor}></div>
            <div className={styles.rectInsideBG}></div>
            <div className={styles.rectOutsideBG}></div>
          </div>
          <div
            className={`${styles.rectRight} ${styles.rectAnimated}`}
            id='rectRight'>
            <div className={styles.rectInsideColor}></div>
            <div className={styles.rectInsideBG}></div>
            <div className={styles.rectOutsideBG}></div>
          </div>
          <h2 className={styles.h2Landing}>
            We provide positive news only. Why?
          </h2>
          <p className={styles.upperInfoText}>
            In a world consumed by negative experiences, emotions, and news,
            it&apos;s time to break free from the chains of the negativity bias.
            Our platform helps you rise above the influence of media
            exploitation by showcasing the power of positive news and stories.
            Join us on a journey of uplifting content, inspiring stories, and a
            refreshing break from the negativity.
          </p>
          <div className={styles.buttonWrapper}>
            <Button inverted onClick={handleNewsButton}>
              News
            </Button>
          </div>
          <p className={styles.bewareText}>
            But beware! All of the news provided by
            <span className={styles.upper}> positivity bias</span> are generated
            by AI - and therefore not real!
          </p>
        </div>
        <div className={styles.trapWrapper} id={trapID}>
          <div className={styles.trapBloat}></div>
          <div className={styles.trapInnerWrapper}>
            <h2 className={styles.h2Landing}>
              Escape the negativity trap <br />
              and challenge your biased perspective!
            </h2>
            <Button inverted onClick={handleTakeQuizButton}>
              Try now
            </Button>
          </div>
        </div>
        <div className={styles.conceptGrid}>
          <div className={styles.projectWhy}>
            This project has been created as part of the Bachelors degree
            program in <br />
            MultiMediaTechnology at the University of Applied Sciences Salzburg.
          </div>
          <h2 className={styles.h2Landing}>CONCEPT & DEVELOPMENT</h2>
          <div className={styles.gridLine}></div>
          <div className={styles.conceptPerson}>
            <p className={styles.conceptName}>Elias Fellinger</p>
            <a href='/'>link to contact</a>
          </div>
          <div className={styles.conceptPerson}>
            <p className={styles.conceptName}>David Grois</p>
            <a href='https://www.linkedin.com/in/david-grois-551366279/'>
              Linkedin
            </a>
          </div>
          <div className={styles.conceptPerson}>
            <p className={styles.conceptName}>Tanja Gruber</p>
            <a href='https://www.linkedin.com/in/tanja-gruber-2b0754232/'>
              Linkedin
            </a>
          </div>
        </div>
      </main>
      <footer className={styles.footerLanding} id={footerID}>
        <span>© 2023</span>
        <Link to='/imprint'>Imprint</Link>
      </footer>
    </>
  )
}

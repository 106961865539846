import { React } from 'react'
import styles from './NavBarLanding.module.scss'
import { Link } from 'react-router-dom'

export const NavBarLanding = () => {
  return (
    <nav className={styles.navBar}>
      <ul>
        <li>
          <Link to='/news/all' id='newsLink'>
            News
          </Link>
        </li>
        <li>
          <Link to='/quiz/start' id='journeyLink'>
            Dive in
          </Link>
        </li>
      </ul>
    </nav>
  )
}

import { React, useEffect } from 'react'
import styles from './Header.module.scss'
import { RotateIcon } from './RotateIcon'

export const Header = () => {
  useEffect(() => {
    const exploreWrapper = document.getElementById('exploreWrapper')

    let finishedAnim = false

    const biasDiv = document.getElementById('biasDiv')

    biasDiv.addEventListener('animationend', () => {
      finishedAnim = true
    })

    function hideExplore () {
      if (!finishedAnim) return

      if (window.scrollY > 20) {
        exploreWrapper.style.opacity = 0
      }
    }

    document.addEventListener('scroll', hideExplore)

    return () => {
      document.removeEventListener('scroll', hideExplore)
    }
  })
  return (
    <header>
      <div className={styles.blurblob}>
        <div className={styles.innerBlob}>
          <svg
            viewBox='0 0 200 200'
            xmlns='http://www.w3.org/2000/svg'
            id='purpleBlob'
            className={`${styles.purpleBlob} ${styles.blob}`}>
            <path
              fill='#8C46F0'
              d='M34.8,-48.4C50,-36.9,70.5,-33.2,75.5,-23.3C80.5,-13.3,69.9,3,64,20.7C58.1,38.4,56.8,57.5,47,68.1C37.1,78.8,18.5,81.1,-0.4,81.6C-19.3,82.2,-38.7,81,-48.1,70.2C-57.5,59.4,-57.1,38.9,-57.5,22.3C-58,5.7,-59.4,-7,-57.9,-21C-56.4,-35,-52.1,-50.4,-41.9,-63.5C-31.8,-76.7,-15.9,-87.7,-3,-83.5C9.8,-79.3,19.7,-59.9,34.8,-48.4Z'
              transform='translate(100 100)'
            />
          </svg>
          <svg
            viewBox='0 0 200 200'
            xmlns='http://www.w3.org/2000/svg'
            className={`${styles.blueBlob} ${styles.blob}`}>
            <path
              fill='#4DD3F3'
              d='M39.6,-63.9C49.6,-62.8,54.9,-48.8,58.5,-36C62,-23.2,63.8,-11.6,63.7,-0.1C63.5,11.4,61.4,22.8,56.1,32.5C50.8,42.2,42.1,50.1,32.2,51.8C22.3,53.5,11.2,48.9,0.7,47.7C-9.7,46.4,-19.4,48.5,-31.1,47.8C-42.7,47.1,-56.2,43.7,-66.9,35.3C-77.5,26.9,-85.1,13.4,-81.9,1.9C-78.7,-9.7,-64.6,-19.4,-53.1,-26.3C-41.6,-33.2,-32.8,-37.3,-24.4,-39.4C-16,-41.4,-8,-41.4,3.4,-47.3C14.8,-53.2,29.5,-65,39.6,-63.9Z'
              transform='translate(100 100)'
            />
          </svg>
          <svg
            viewBox='0 0 200 200'
            xmlns='http://www.w3.org/2000/svg'
            id='orangeBlob'
            className={`${styles.orangeBlob} ${styles.blob}`}>
            <path
              fill='#FE5832'
              d='M28.8,-49.3C34.1,-46.8,32.9,-32.6,39.5,-22.4C46.1,-12.2,60.4,-6.1,62.2,1C64,8.2,53.2,16.3,47.6,28.2C42,40.1,41.6,55.6,34.5,59.6C27.4,63.5,13.7,55.8,0.6,54.7C-12.5,53.6,-24.9,59.2,-34.6,56.8C-44.3,54.3,-51.2,43.9,-48.3,33.2C-45.3,22.4,-32.5,11.2,-34.2,-1C-35.9,-13.1,-52.1,-26.3,-56.2,-39.1C-60.3,-51.9,-52.3,-64.4,-41,-63.4C-29.6,-62.3,-14.8,-47.8,-1.5,-45.2C11.7,-42.6,23.5,-51.8,28.8,-49.3Z'
              transform='translate(100 100)'
            />
          </svg>
        </div>
      </div>
      <div className={styles.backgroundBlurrer}></div>
      <div className={styles.darkBGanim}></div>
      <RotateIcon></RotateIcon>
      <div className={styles.headingText} id='headerText'>
        Breaking the
        <br />
        stereotype
        <br />
        of
        <br /> negative <br />
        fake news
      </div>
      <div className={styles.exploreWrapper} id='exploreWrapper'>
        <div className={styles.explore}>
          <span>Scroll to explore</span>
          <svg
            width='3rem'
            height='3rem'
            viewBox='0 0 90 33'
            version='1.1'
            xmlSpace='preserve'
            style={{
              fillRule: 'evenodd',
              clipRule: 'evenodd',
              strokeLinecap: 'round',
              strokeLinejoin: 'round'
            }}>
            <g transform='matrix(1, 0, 0, 1, -5.8946, -48.5)'>
              <g transform='matrix(1.47883, 0, 0, 1, -23.4143, 0)'>
                <path
                  d='M79.167,50L50,79.167L20.833,50'
                  style={{
                    fill: 'none',
                    fillRule: 'nonzero',
                    stroke: 'black',
                    strokeWidth: '2px'
                  }}
                />
              </g>
            </g>
          </svg>
        </div>
      </div>
      <h1 className={styles.biasWrapper}>
        <div className={styles.doppelPunktP}>p</div>
        <div className={styles.bias}>
          <div className={styles.biasDiv} id='biasDiv'>
            ositivity <br />
            bias
          </div>
        </div>
      </h1>
    </header>
  )
}

import React from 'react'
import styles from '../button.module.scss'
import { Link } from 'react-router-dom'
import linkStyles from './buttonLink.module.scss'

export const ButtonLink = ({ to, size, inverted, children }) => {
  const sizeStyle = (size === 'small' && styles.small) || ((size === 'medium' || size === undefined) && styles.medium) || (size === 'large' && styles.large)
  const colorStyle = inverted ? styles.invertedColors : styles.normalColors

  return (
        <Link className={`${styles.button} ${sizeStyle} ${colorStyle} ${linkStyles.link}`} to={to}>{children}</Link>
  )
}

import React from 'react'
import styles from './singleArticle.module.scss'
import PropTypes from 'prop-types'
import { ContinueReadingSection } from '../ContinueReadingSection/ContinueReadingSection'
import { ImagePlaceholder } from '../ImagePlaceholder/ImagePlaceholder'

export const SingleArticle = ({
  className,
  content,
  continueReadingArticles
}) => {
  return (
    <div className={`${styles.singleArticle} ${className}`}>
      <div className={styles.header}>
        <div className={styles.categoryDateWrapper}>
          <span className={styles.category}>{content.category}</span>
          <span className={styles.date}>
            {new Date(content.date).toLocaleDateString()}
          </span>
        </div>
        <h1>{content.heading}</h1>
      </div>
      <div className={styles.contentWrapper}>
        <ImagePlaceholder
          src={content.imageURL}
          alt={content.ImagePlaceholder}
        />
        <p>
          {content.text}
          <br></br>
          <span className={styles.author}>Author: ChatGPT</span>
        </p>
      </div>
      <ContinueReadingSection
        className={styles.continueReading}
        content={continueReadingArticles}></ContinueReadingSection>
    </div>
  )
}

export default SingleArticle

SingleArticle.propTypes = {
  className: PropTypes.string,
  content: PropTypes.shape({
    category: PropTypes.string,
    date: PropTypes.instanceOf(Date),
    heading: PropTypes.string,
    imageURL: PropTypes.string,
    imageAlt: PropTypes.string,
    text: PropTypes.string
  }).isRequired
}

import React from 'react'
import styles from './errorBox.module.scss'

export const ErrorBox = ({ error, className, children }) => {
  return (
        <div className={`${className} ${styles.loader}`}>
            {(error) && children}
        </div>
  )
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".imagePlaceholder_placeholder__7bmO6 {\n  aspect-ratio: 1/1;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: rgb(241, 241, 241);\n  background: linear-gradient(145deg, rgb(241, 241, 241) 0%, rgb(221, 221, 221) 96%);\n}\n@keyframes imagePlaceholder_spin__fzFQO {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n.imagePlaceholder_placeholder__7bmO6 svg {\n  animation: imagePlaceholder_spin__fzFQO 1s linear infinite;\n}", "",{"version":3,"sources":["webpack://./src/stories/ImagePlaceholder/imagePlaceholder.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,8BAAA;EACA,kFAAA;AACJ;AACI;EACI;IACE,uBAAA;EACR;EACM;IACE,yBAAA;EACR;AACF;AAEI;EACI,0DAAA;AAAR","sourcesContent":[".placeholder{\n    aspect-ratio: 1/1;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: rgb(241,241,241);\n    background: linear-gradient(145deg, rgba(241,241,241,1) 0%, rgba(221,221,221,1) 96%);\n\n    @keyframes spin {\n        from {\n          transform: rotate(0deg);\n        }\n        to {\n          transform: rotate(360deg);\n        }\n    }\n\n    svg{\n        animation: spin 1s linear infinite;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"placeholder": "imagePlaceholder_placeholder__7bmO6",
	"spin": "imagePlaceholder_spin__fzFQO"
};
export default ___CSS_LOADER_EXPORT___;

import { create } from 'zustand'
import { BACKEND_URL } from '../http'

export const useCategoriesStore = create((set) => ({
  allCategories: [],
  allCategoriesLoading: true,
  allCategoriesError: false,
  fetchAllCategories: async () => {
    set({ allCategoriesLoading: true })
    const url = `${BACKEND_URL}/v1/categories`
    fetch(url)
      .then((response) => {
        if (response.status !== 200) {
          set({ allCategories: [], allCategoriesLoading: false, allCategoriesError: true })
          return
        }
        return response.json()
      })
      .then((json) => {
        set({ allCategories: json, allCategoriesLoading: false, allCategoriesError: false })
      })
      .catch((e) => {
        set({ allCategories: [], allCategoriesLoading: false, allCategoriesError: true })
      })
  }
}))

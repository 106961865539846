import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
import { Imprint } from './stories/Imprint/Imprint'
import { LandingPage } from './stories/LandingPage/LandingPage'
import { NewsPage } from './stories/NewsPage/NewsPage'
import { SingleArticlePage } from './stories/SingleArticlePage/SingleArticlePage'
import StartScreen from './stories/Start-Screen/StartScreen'
import QuizContainer from './stories/Quiz/QuizContainer/QuizContainer'
import { EndScreen } from './stories/Quiz/EndScreen/EndScreen'
import { GenerateArticePage } from './stories/GenerateArticlePage/GenerateArticlePage'

export const router = createBrowserRouter([
  {
    path: '/',
    element: <LandingPage />
  },
  {
    path: '/news/:category',
    element: <NewsPage />
  },
  {
    path: '/article/create',
    element: <GenerateArticePage />
  },
  {
    path: '/article/:id',
    element: <SingleArticlePage />
  },
  {
    path: '/imprint',
    element: <Imprint />
  },
  {
    path: '/quiz',
    children: [
      {
        path: 'start',
        element: <StartScreen />
      },
      {
        path: ':questionId',
        element: <QuizContainer />
      },
      {
        path: 'end',
        element: <EndScreen />
      }
    ]
  }
])

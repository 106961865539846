import React, { useState } from 'react'
import styles from '../questions.module.scss'
import { Button } from '../../Button/Button/Button'

export const MultipleChoice = ({ question, options, answer }) => {
  const [selectedOptions, setSelectedOptions] = useState([])
  const [submitted, setSubmitted] = useState(false)
  const [isCorrect, setIsCorrect] = useState(false)

  const handleOptionClick = (optionId) => {
    if (submitted) {
      return
    }

    if (optionId === 4) {
      setSelectedOptions([4])
    } else {
      if (selectedOptions.includes(optionId)) {
        setSelectedOptions(selectedOptions.filter((id) => id !== optionId))
      } else {
        setSelectedOptions([...selectedOptions, optionId])
      }

      if (selectedOptions.includes(4)) {
        setSelectedOptions([optionId])
      }
    }
  }

  const handleSubmit = () => {
    const isAnswerCorrect =
      (selectedOptions.length === 3 && selectedOptions.includes(1) && selectedOptions.includes(2) && selectedOptions.includes(3)) ||
      (selectedOptions.length === 1 && selectedOptions.includes(4))
    setIsCorrect(isAnswerCorrect)
    setSubmitted(true)
  }
  return (
        <div className={styles.multipleChoiceWrapper}>
            <span className={styles.questionType}>Multiple choice</span>
            <p className={styles.question}>{question}</p>
            <ul className={styles.multipleChoiceOptions}>
            {options.map((option) => (
          <li
            key={option.id}
            className={`${styles.option} ${selectedOptions.includes(option.id) ? styles.selected : ''}`}
            onClick={() => handleOptionClick(option.id)}
          >
            <div className={`${styles.optionSquare} ${selectedOptions.includes(option.id) ? styles.filled : ''}`}></div>
            <span className={styles.optionLabel}>{option.answer}</span>
          </li>
            ))}
            </ul>
            {submitted
              ? (
        <div>
          {isCorrect
            ? (
            <div className={`${styles.animCheck}`}>
              <div className={styles.check}></div>
            </div>
              )
            : (
              <div>
               <div className={`${styles.animX}`}>
                <div className={styles.X}></div>
               </div>
               <span className={styles.message}>The correct answer would have been: All of the above</span>
            </div>
              )}
        </div>
                )
              : (
        <div>
          <Button inverted onClick={handleSubmit}>Submit</Button>
        </div>
                )}
        </div>
  )
}

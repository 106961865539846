import React from 'react'
import styles from './loadingSpinner.module.scss'

export const LoadingSpinner = ({ className }) => {
  return (
        <div className={`${styles.loadingSpinner} ${className}`}>
            <p>Loading</p>
            <img src='/loader.svg' alt='Loading icon'></img>
        </div>
  )
}

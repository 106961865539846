import { useEffect, useState } from 'react'

export const useInfiniteEndpoint = (url, converter) => {
  const [page, setPage] = useState(1)
  const [response, setResponse] = useState([])
  const [error, setError] = useState(null)
  const [hasMore, setHasMore] = useState(true)

  /* reset data if url changes */

  useEffect(() => {
    setPage(1)
    setResponse([])
  }, [url])

  const fetchData = (page) => {
    fetch(url.replace('$page', page))
      .then((response) => {
        if (response.status !== 200) {
          setResponse(undefined)
          setError(true)
          return
        }
        return response.json()
      })
      .then((json) => {
        if (json.status && json.status !== 200) {
          setResponse(undefined)
          setError(true)
          return
        }

        if (json.length === 0) {
          setHasMore(false)
          return
        }

        setResponse(oldResponse => {
          const updatedResponse = json.map(item => converter(item)).filter(item => {
            return !oldResponse.some(entry => entry.id === item.id)
          })
          return [...oldResponse, ...updatedResponse]
        })
      })
      .catch((error) => {
        setError(error)
      })
  }

  useEffect(() => {
    fetchData(page)
  }, [url])

  return {
    response,
    error,
    hasMore,
    fetch: () => {
      // use manual increased page, as setState is asynchron and setPage was to slow and used the old page
      fetchData(page + 1)
      setPage(page + 1)
    }
  }
}

import React from 'react'
import * as PropTypes from 'prop-types'
import styles from './article.module.scss'
import { Link } from 'react-router-dom'
import { ImagePlaceholder } from '../ImagePlaceholder/ImagePlaceholder'

export const Article = ({ className, content }) => {
  const truncatedText = content.text.length > 300 ? content.text.slice(0, 300) + '...' : content.text

  return (
    <Link className={`${styles.article} ${className}`} to={`/article/${content.id}`}>
      <div className={styles.categoryDateWrapper}>
        <span className={styles.category}>{content.category}</span>
        {new Date().toDateString() === content.date.toDateString()
          ? <span className={styles.latest}>Latest</span>
          : <span className={styles.date}>{new Date(content.date).toLocaleDateString()}</span>
        }
      </div>
      {/* <img src={content.imageURL} alt={content.imageAlt}/> */}
      <ImagePlaceholder src={content.imageURL} alt={content.imageAlt}></ImagePlaceholder>
      <h3 className={styles.heading}>{content.heading}</h3>
      <p className={styles.text}>{truncatedText}</p>
    </Link>
  )
}
export default Article

Article.propTypes = {
  className: PropTypes.string,
  content: PropTypes.shape({
    id: PropTypes.number,
    category: PropTypes.string,
    date: PropTypes.instanceOf(Date),
    heading: PropTypes.string,
    imageURL: PropTypes.string,
    imageAlt: PropTypes.string,
    text: PropTypes.string
  }).isRequired
}

import React from 'react'
import styles from '../button.module.scss'

export const Button = ({ type, onClick, size, inverted, children }) => {
  const sizeStyle = (size === 'small' && styles.small) || ((size === 'medium' || size === undefined) && styles.medium) || (size === 'large' && styles.large)
  const colorStyle = inverted ? styles.invertedColors : styles.normalColors

  return (
        <button className={`${styles.button} ${sizeStyle} ${colorStyle}`} onClick={onClick} type={type}>{children}</button>
  )
}

export default Button

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header_headerWrapper__cTVDc {\n  width: 100%;\n}\n.header_headerWrapper__cTVDc h1 {\n  font-size: 11vw;\n  margin: 0;\n  text-align: center;\n  text-transform: uppercase;\n  font-weight: 500;\n}\n@media (max-width: 600px) {\n  .header_headerWrapper__cTVDc h1 {\n    padding-block: 3vh;\n  }\n}\n.header_headerWrapper__cTVDc .header_loader__\\+Jqxc {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding-block: 2rem;\n}", "",{"version":3,"sources":["webpack://./src/stories/Header/header.module.scss","webpack://./src/stories/mixins.scss"],"names":[],"mappings":"AACA;EACI,WAAA;AAAJ;AACI;EACI,eAAA;EACA,SAAA;EACA,kBAAA;EACA,yBAAA;EACA,gBAAA;AACR;ACRI;EDEA;IAOQ,kBAAA;EAGV;AACF;AAAI;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;AAER","sourcesContent":["@import '../mixins.scss';\n.headerWrapper{\n    width: 100%;\n    h1{\n        font-size: 11vw;\n        margin: 0;\n        text-align: center;\n        text-transform: uppercase;\n        font-weight: 500;\n        @include mobile{\n            padding-block: 3vh;\n        }\n    }\n\n    .loader{\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        padding-block: 2rem;\n    }\n}\n","@mixin mobile {\n    @media (max-width: 600px) {\n        @content;\n    }\n}\n\n@mixin desktop {\n    @media (min-width: 601px) {\n        @content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerWrapper": "header_headerWrapper__cTVDc",
	"loader": "header_loader__+Jqxc"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import Slider from '../Slider/Slider'
import styles from '../questions.module.scss'

export const SliderQuestion = ({ question, sliderOptions }) => {
  return (
        <div className={styles.sliderQuestionWrapper}>
            <span className={styles.questionType}>Evaluate</span>
            <p>&ldquo;{question}&rdquo;</p>
            <span>How does reading this make you feel?</span>
            <Slider options={sliderOptions}/>
        </div>
  )
}

export default SliderQuestion
